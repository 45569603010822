.error-message {
	color: red;
	font-size: 1.5rem;
}
/* .form-group {
	margin: 0 15px 20px;
	padding: 0;
	border-style: none;
	background-color: #7795f8;
	will-change: opacity, transform;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 #829fff;
	border-radius: 4px;
} */

/* .card-group {
	background: #7795f8;
	box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
		0 3px 6px 0 rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	margin-bottom: 20px;
} */
/* .card-field {
	background: transparent;
	font-weight: 300;
	border: 0;
	color: #31325f;
	outline: none;
	flex: 1;
	padding-right: 10px;
	padding-left: 10px;
	cursor: text;
} */

/* .row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
	border-top: 1px solid #819efc;
} */
/* 
.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
} */

/* button {
	display: block;
	font-size: 16px;
	width: calc(100% - 30px); 
	height: 40px;
	margin: 40px 15px 0;
	background-color: #f6a4eb;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 #ffb9f6;
	border-radius: 4px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
} */

/* button:active {
	background-color: #d782d9;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 #e298d8;
	transform: scale(0.99);
}

.App {
	margin-left: 25%;
	margin-top: 5%;
	margin-right: 25%;
}

h1,
h3 {
	text-align: center;
} */

/* ------------------------- */
/* .checkout {
	width: 50%;
	margin: 0 auto;
	text-align: center;
}
.checkout h4 {
	margin: 0;
	margin-top: 1rem;
}
.checkout button {
	width: 200px;
	margin: 0 auto;
}
.checkout .stripe-card {
	margin: 1rem 0;
}
.checkout .card-number {
	margin-top: 2rem;
}
.checkout .card-element {
	border: 1px solid grey;
	border-radius: 3px;
	padding: 10px;
	width: 55%;
	margin: 0 auto;
}
.checkout .error {
	border: 1px solid red;
}
.checkout .error-message {
	color: red;
	font-size: 1.5rem;
} */

/* .checkout {
	width: 50%;
	margin: 0 auto;
	text-align: center;
}
h4 {
	margin: 0;
	margin-top: 1rem;
}
button {
	width: 200px;
	margin: 0 auto;
}
stripe-card {
	margin: 1rem 0;
}
card-number {
	margin-top: 2rem;
}
card-element {
	border: 1px solid grey;
	border-radius: 3px;
	padding: 10px;
	width: 55%;
	margin: 0 auto;
}
error {
	border: 1px solid red;
}
error-message {
	color: red;
	font-size: 1.5rem;
} */
