.map {
	width: 70vw;
	height: 60vh;
	position: relative;
	margin-top: 3rem;
	margin-bottom: 5rem;
	border-style: none solid;
	color: #141414;
}

.location-icon {
	font-size: 2rem;
	color: red;
}

.loader {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}

.loader img {
	width: 400px;
}

.loader h1 {
	margin-top: -100px;
}

.location-info {
	position: absolute;
	top: 80px;
	right: 50px;
	width: 350px;
	min-height: 200px;
	padding: 20px;
	background-color: #f89406;
	border-radius: 10px;
	font-size: 18px;
	color: #141414;
	border: 2px solid #141414;
}

.location-info ul {
	list-style-type: none;
	padding: 0;
}

.location-info li {
	padding: 5px 0;
}
