/**************************/
/* GENRAL REUSABLE COMPONENTS */
/**************************/

/* Step 1 in CSS. Write the below initialization  */
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html {
	/* font-size: 10px; */
	/* 10px / 16px = 0.625 = 62.5% */
	/* Percentage of user's browser font-size setting */
	font-size: 62.5%;
}

/* .container { */
/* 1140px */
/* max-width: 120rem;
	padding: 0 3.2rem;
	margin: 0 auto;
} */

body {
	font-family: 'Rubik', sans-serif;
	line-height: 1;
	font-weight: 400;
	color: #555;
}

.grid {
	display: grid;
	column-gap: 6.4rem;
	row-gap: 9.6rem;
}

.grid--2-cols {
	grid-template-columns: repeat(2, 1fr);
}

.grid--center-v {
	align-items: center;
}

/**************************/
/* HOW IT WORKS SECTION */
/**************************/

.section-how {
	padding: 9.6rem 0;
}

.step-number {
	font-size: 8.6rem;
	font-weight: 600;
	color: #ddd;
	margin-bottom: 1.2rem;
}

.step-description {
	font-size: 1.8rem;
	line-height: 1.8;
}

.step-img-box {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
}

.step-img-box::before,
.step-img-box::after {
	content: '';
	display: block;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.step-img-box::before {
	width: 60%;
	/* height: 60%; */

	/* 60% of parent's width */
	padding-bottom: 60%;

	background-color: #fdf2e9;
	z-index: -2;
}

.step-img-box::after {
	width: 45%;
	padding-bottom: 45%;
	background-color: #fae5d3;
	z-index: -1;
}

.step-img {
	width: 35%;
	/* z-index: 10; */
}

/**************************/
/* Bootstrap NAV Bar */
/**************************/

.bootNavBarLogo {
	font-size: 2rem;
	margin-top: -10px;
	margin-bottom: -10px;
}

.bootNavBar {
	font-size: 1.15rem;
}
