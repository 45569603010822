.searchbar {
	/* width: 100vw; */
	margin-top: 1rem;
	/* height: 100vh; */
	height: 50rem;
	display: flex;
	align-items: center;
	flex-direction: column;
	font-family: 'Nunito', sans-serif;
	background-image: linear-gradient(90deg, #2f7336, #aa3a38);
}

body {
	padding: 0;
	margin: 0;
}

.searchInputs {
	margin-top: 9rem;
	display: flex;
}
.search input {
	background-color: white;
	border: 0;
	border-radius: 2px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	font-size: 1.7rem;
	padding: 2rem;
	/* height: 30px; */
	width: 40rem;
}

.searchIcon {
	height: 6rem;
	width: 7rem;
	background-color: white;
	display: grid;
	place-items: center;
}

input:focus {
	outline: none;
}
.searchIcon svg {
	font-size: 35px;
}

.clearBtn {
	cursor: pointer;
}

.dataResult {
	margin-top: 5px;
	width: 47rem;
	height: 20rem;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	overflow: hidden;
	overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
	display: none;
}

.dataResult .dataItem {
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	color: black;
}

.dataItem p {
	margin-left: 10px;
}

a {
	text-decoration: none;
}

/* a:hover {
	background-color: lightgrey;
} */
