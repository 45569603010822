/* Index.css is where you put in the custom tags */
main {
	min-height: 80vh;
}

h3 {
	padding: 1rem 0;
}

h1 {
	font-size: 1.8rem;
	padding: 1rem 0;
}

h2 {
	font-size: 1.4rem;
	padding: 0.5rem 0;
}

.rating span {
	margin: 0.1rem;
}

/* sairam logo on the header component */
.navbar-brand {
	display: flex;
	align-items: center;
}
.navbar-brand > img {
	padding: 0px 20px;
}

/* carousel not used in this project and can be deleted*/
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
	display: flex;
}
.carousel-caption {
	position: absolute;
	top: 0;
}

.carousel-caption h2 {
	color: #fff;
}

.carousel img {
	height: 300px;
	width: 300px;
	padding: 30px;
	margin: 40px;
	border-radius: 50%;
	margin-left: auto;
	margin-right: auto;
}
.carousel a {
	margin: 0 auto;
}
@media (max-width: 900px) {
	.carousel-caption h2 {
		font-size: 2.5vw;
	}
}

/* above carousel code is not used in this project and can be deleted

/* Banner Image */
/* .banner-image { */
/* width: 1100;
	height: 250;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;  
	position: absolute;  
	object-fit: cover; */
/* } */

/* Below styles used in the add items, delete items etc. buttons in the cart page */
button {
	border: none;
	padding: 0.5rem 1rem;
	cursor: pointer;
	font-size: 10px;
	margin-right: 5px;
}

.btn-increase {
	color: #fff;
	background-color: #1a1a1a;
}

.btn-decrease,
.btn-trash {
	color: #fff;
	background-color: #d9534f;
}

/* .btns-container {
	button {
		border: none;
		padding: 0.5rem 1rem;
		cursor: pointer;
		font-size: 10px;
		margin-right: 5px;
	}

	.btn-increase {
		color: #fff;
		background-color: #1a1a1a;
	}

	.btn-decrease,
	.btn-trash {
		color: #fff;
		background-color: #d9534f;
	}
} */
