* {
	box-sizing: border-box;
}

:root {
	--heights: 45vh;
	--widths: 100%;
}

.slider-container {
	height: var(--heights);
	width: var(--widths);
	position: relative;
	margin: auto;
	/* padding-bottom: 0px;
	border-bottom: 0px;
	margin-bottom: 0px; */
	overflow: hidden;
	/* border-radius: 26px; */
	position: relative;
	border: 2px solid #fff;
	border-bottom-left-radius: 26px;
	border-bottom-right-radius: 26px;
	backdrop-filter: blur(10px);
	margin-bottom: 1rem;
	margin-top: -0.5rem;
}

.active {
	display: inline-block;
}

.inactive {
	display: none;
}

.slides {
	height: var(--heights);
	width: var(--widths);
	position: relative;
}

.slide-image {
	width: 100%;
	height: 100%;
	position: absolute;
	object-fit: cover;
	/* border-radius: 26px; */
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	/* border-top-left-radius: 12px;
	border-top-right-radius: 12px; */

	position: relative;
	border: 2px solid #fff;
}

.slide-title,
.slide-text {
	width: 100%;
	height: 100%;
	color: white;
	position: absolute;
	text-align: center;
	font-size: 2.5rem;
}
@media screen and (max-width: 768px) {
	.slide-title,
	.slide-text,
	h1 {
		font-size: 1.5rem;
	}
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
	.slide-image {
		width: 100%;
		height: 125%;
		position: absolute;
		object-fit: cover;
		/* border-radius: 26px; */
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
		/* border-top-left-radius: 12px;
			border-top-right-radius: 12px; */

		position: relative;
		border: 2px solid #fff;
	}
}

.slide-text {
	top: 55%;
}

.prev {
	cursor: pointer;
	z-index: 100;
	position: absolute;
	top: 50%;
	width: auto;
	padding: 1rem;
	margin-top: -3rem;
	font-size: 30px;
	font-weight: bold;
	border-radius: 0 5px 5px 0;
}

.next {
	cursor: pointer;
	z-index: 100;
	position: absolute;
	top: 50%;
	width: auto;
	padding: 1rem;
	margin-top: -3rem;
	font-size: 30px;
	font-weight: bold;
	border-radius: 0 5px 5px 0;
	right: 0;
}

.prev:hover {
	color: white;
	background-color: rgba(0, 0, 0, 0.6);
	transition: all 0.5s ease-in;
}

.next:hover {
	color: white;
	background-color: rgba(0, 0, 0, 0.6);
	transition: all 0.5s ease-in;
	overflow: hidden;
}

/* .next {
	right: 0;
	border-radius: 5px 0 0 5px;
} */

.all-dots {
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	top: 85%;
	justify-content: center;
	/* align-items: center; */
	z-index: 200;
}

.dot {
	cursor: pointer;
	height: 1rem;
	width: 1rem;
	margin: 0 4px;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 50%;
	display: inline-block;
}

.active-dot,
.dot:hover {
	/* background-color: rgba(255, 255, 255, 0.5); */
	background-color: rgba(0, 0, 0, 0.3);
}
